import "./style.scss"


import { useNavigate } from 'react-router-dom';

export default function Action(){
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/registration');
      };
    return(
        <div className="action-container">
            <div className="plate-1">
                <div className="action-title">
                Grab your Ticket now
                </div>
                <div className="action-desc-1">
                    Don't Miss This Wonderful Oppurtinity
                </div>
                <div className="action-desc-2">
                    Hurry up ! Limited seats left 
                </div>
                <div className="action-desc-3">
                    * Wearing Dhoti Kurta is compulsary in the event *
                </div>
            </div>
            <div className="plate-2">
                <div className="plate-2-head">
                    Registration Ticket
                </div>
                {/* <div className="price">
                    ₹400
                </div> */}
                <div className="price2">
                    ₹300/-
                </div>
{/*                 <div className="offer">
                    * Offer Valid Till 04 August *
                </div> */}
                <ul className="features">
                    <li>
                    Seminar</li>
                    <li>Debate</li>
                    <li>Mantra Rock Show</li>
                    <li>Theatre</li>
                    <li>Dinner Buffet</li>
                </ul>
                    <button className="btn-purchase" onClick={handleRedirect}>Purchase Ticket</button>
            </div>
            <div className="plate-2">
                <div className="plate-2-head">
                    Registration + Dhoti Kurta
                </div>
                {/* <div className="price">
                    ₹900
                </div> */}
                <div className="price2">
                    ₹700/-
                </div>
{/*                 <div className="offer">
                    * Offer Valid Till 04 August *
                </div> */}
                <ul className="features">
                    <li>You can purchase dhoti Kurta at the discounted price of just Rs.400</li>
                </ul>
                    <button className="btn-purchase" onClick={handleRedirect}>Purchase Ticket</button>
            </div>
        </div>
    )
}