
import React, { useState, useEffect } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Edit,CommandColumn,Filter} from '@syncfusion/ej2-react-grids';
import { Header } from '../Components1';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useStateContext } from '../contexts/ContextProvider';
const Registrations = () => {
  const { currentColor } = useStateContext();
  const [userData, setUserData] = useState([]);
  const [currentAction, setCurrentAction] = useState(null); // To track the current action type
  const toolbarOptions = ['Search', 'Edit'];
  const editing = { allowDeleting: true, allowEditing: true, mode: 'Dialog' }; // Enable dialog editing
  const [cityFilter, setCityFilter] = useState('');
   const [paymentModeFilter, setPaymentModeFilter] = useState('');
   const [originalData, setOriginalData] = useState([]); // Store the original data
   
  // useEffect(() => {
  //   // Fetch user data from the API
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('http://localhost:5000/api/getAllUsers');
  //       const data = await response.json();
  //       console.log('Fetched data:', data); // Debug: Log fetched data

  //       if (data.success) {
  //         setUserData(data.users);
  //       } else {
  //         console.error('Failed to fetch user data:', data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const getBaseURL = () => {
    const host = window.location.host;
  
    if (host.includes('localhost')) {
      return 'http://localhost:5000'; // Local API URL
    } else {
      return 'https://iyfghaziabad.in'; // Production API URL
      }
    };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${getBaseURL()}/api/getAllUsers`);
        const data = await response.json();
        console.log('Fetched data:', data);

        if (data.success) {
          setOriginalData(data.users); // Store the original data
          setUserData(data.users); // Initialize userData with the full dataset
        } else {
          console.error('Failed to fetch user data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  //for filteration
  useEffect(() => {
    let filteredData = originalData;

    if (cityFilter) {
      filteredData = filteredData.filter(user => user.city === cityFilter);
    }

    if (paymentModeFilter) {
      filteredData = filteredData.filter(user => user.paymentMode === paymentModeFilter);
    }

    // Reset to originalData if both filters are cleared
    if (!cityFilter && !paymentModeFilter) {
      filteredData = originalData;
    }

    setUserData(filteredData);
  }, [cityFilter, paymentModeFilter, originalData]);
  const getUniqueValues = (data, key) => {
    return data.reduce((acc, current) => {
      const x = acc.find(item => item.value === current[key]);
      if (!x) {
        acc.push({ text: current[key], value: current[key] });
      }
      return acc;
    }, []);
  };
  // Handle the action for saving (both add and edit)
  const actionBegin = async (args) => {
    if (args.requestType === 'save') {
      if (args.action === 'add') {
        // Handle adding a new user
        try {
          const response = await fetch(`${getBaseURL()}/api/registrationData`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(args.data)
          });
          const result = await response.json();
          console.log('Add result:', result); // Debug: Log add result

          if (result.success) {
            setUserData([...userData, result.user]);
          } else {
            console.error('Failed to add user:', result.message);
          }
        } catch (error) {
          console.error('Error adding user:', error);
        }
      } else if (args.action === 'edit') {
        // Handle editing an existing user
        try {
          const response = await fetch(`${getBaseURL()}/api/updateUser/${args.data._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(args.data)
          });
          const result = await response.json();
          console.log('Update result:', result); // Debug: Log update result

          if (result.success) {
            setUserData(userData.map(user => user._id === result.user._id ? result.user : user));
          } else {
            console.error('Failed to update user:', result.message);
          }
        } catch (error) {
          console.error('Error updating user:', error);
        }
      }
    } else if (args.requestType === 'delete') {
      setCurrentAction('delete');
    }
  };

  // Handle the action for deleting
  const actionComplete = async (args) => {
    console.log('Action Complete Args:', args); // Debug log to inspect args
  
    if (args.requestType === 'delete' && currentAction === 'delete') {
      const userId = args.data && args.data._id ? args.data._id : null;

      if (!userId) {
        console.error('No user ID found for delete operation');
        return;
      }

      try {
        const response = await fetch(`${getBaseURL()}/api/deleteUser/${userId}`, {
          method: 'DELETE'
        });
        const result = await response.json();
        console.log('Delete result:', result); // Debug log
  
        if (result.success) {
          setUserData(userData.filter(user => user._id !== userId));
          setCurrentAction(null); // Reset action after successful delete
        } else {
          console.error('Failed to delete user:', result.message);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  // Handle action failure
  const actionFailure = (args) => {
    console.error('Action Failure Args:', args); // Debug log for action failures
  };
  

  // Assuming you have an array of cities and payment modes to populate the dropdowns
  const cities = [{ text: 'All Cities', value: '' }, ...getUniqueValues(originalData, 'city')];
  const paymentModes = [{ text: 'All Payment Modes', value: '' }, ...getUniqueValues(originalData, 'paymentMode')];
  
  const handleCityChange = (value) => {
    setCityFilter(value || ''); // If "All" is selected, reset the filter
  };

  const handlePaymentModeChange = (value) => {
    setPaymentModeFilter(value || ''); // If "All" is selected, reset the filter
  };
  //for editing Payment mode to cash confirmed 
  const handleConfirmPayment = async (args) => {
    if (!args || !args.rowData) {
      console.error('Args or rowData is undefined:', args);
      return;
    }
  
    const user = args.rowData;
    console.log('User:', user); // Log the user object to check its structure
  
    if (!user || !user._id) {
      console.error('User data or _id not found');
      return;
    }
  
    try {
      const updatedUser = { ...user, paymentMode: 'cash_confirmed' };
  
      const response = await fetch(`${getBaseURL()}/api/updateUser/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedUser)
      });
  
      const result = await response.json();
      if (result.success) {
        setUserData(userData.map(u => u._id === user._id ? result.user : u));
      } else {
        console.error('Failed to confirm payment:', result.message);
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
    }
  };
  
  //For Downloading the data
  const downloadCSV = () => {
    const csvData = userData.map(user => ({
      Name: user.name,
      Email: user.email,
      City: user.city,
      Contact: user.contact,
      Facilitator: user.facilitator,
      PaymentMode: user.paymentMode,
      RegisteredBy: user.registeredBy,
    }));
    const csvRows = [
      Object.keys(csvData[0]).join(','), // headers
      ...csvData.map(row => Object.values(row).join(',')) // data rows
    ];
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'registrations.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Registrations" />
      <div className="flex mb-4"
     style={{ color: currentColor }}>
      <div className="mr-4"
      >
        <DropDownListComponent 
          dataSource={cities}
          style={{ color: currentColor }}
          placeholder="Filter by City"
          fields={{ text: 'text', value: 'value' }}
          change={(e) => handleCityChange(e.value)}
          value={cityFilter}
        />
      </div>
      <div>
        <DropDownListComponent 
          dataSource={paymentModes}
          style={{ color: currentColor }}
          fields={{ text: 'text', value: 'value' }}
          placeholder="Filter by Payment Mode"
          change={(e) => handlePaymentModeChange(e.value)}
          value={paymentModeFilter}
        />
      </div>
      <button onClick={downloadCSV}
      className='bg-green-500 text-white p-2 rounded ml-5'
      style={{ backgroundColor: currentColor }}
      >
        Download List
      </button>
    </div>
      <GridComponent
        dataSource={userData}
        width="auto"
        allowPaging
        allowSorting
        // allowFiltering={true}
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        actionBegin={actionBegin}
        actionComplete={actionComplete}
        actionFailure={actionFailure}
      >
        <ColumnsDirective  style={{ backgroundColor: currentColor }}>
          {/* Define the columns based on the user data structure */}
          <ColumnDirective field="name" headerText="Name" width="150" textAlign="Left" />
          <ColumnDirective field="email" headerText="Email" width="200" textAlign="Left" />
          <ColumnDirective field="city" headerText="City" width="100" textAlign="Left" allowFiltering={true}/>
          <ColumnDirective field="contact" headerText="Contact" width="150" textAlign="Left" />
          {/* <ColumnDirective field="chanting" headerText="Chanting" width="150" textAlign="Left" /> */}
          <ColumnDirective field="facilitator" headerText="Facilitator" width="150" textAlign="Left" />
          {/* <ColumnDirective field="dob" headerText="Date of Birth" width="150" textAlign="Left" type="date" format="yMd" /> */}
          {/* <ColumnDirective field="wantsDhotiKurta" headerText="Wants Dhoti/Kurta" width="150" textAlign="Left" /> */}
          <ColumnDirective field="paymentMode" headerText="Payment Mode" width="150" textAlign="Left" allowFiltering={true}/>
          {/* <ColumnDirective field="promoCode" headerText="Promo Code" width="150" textAlign="Left" /> */}
          <ColumnDirective field="registeredBy" headerText="Registered By" width="150" textAlign="Left" />
          {/* <ColumnDirective field="_id" headerText="Actions" width="150" textAlign="Center"
  commands={[{
    buttonOption: {
      content: '✔', // Tick mark icon
      cssClass: 'e-flat',
      click:handleConfirmPayment // Pass rowData explicitly
    }
  }]} /> */}



        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Edit,CommandColumn]} />
      </GridComponent>
    </div>
  );
};

export default Registrations;
