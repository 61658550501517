import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './styleform.css';
import img1 from './img/Viplava text1.png';
const getBaseURL = () => {
  const host = window.location.host;

  if (host.includes('localhost')) {
    return 'http://localhost:5000'; // Local API URL
  } else {
    return 'https://iyfghaziabad.in'; // Production API URL
    }
  };
const PaymentMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    email,
    name,
    city,
    contact,
    chanting,
    facilitator,
    occupation,
    initiatedName,
    selectedOption,
    dob,
    registeredBy,
  } = location.state || {};


   
  const handlePaymentOptionSelect = async (event) => {
    event.preventDefault();
    setLoading(true); //show loader when button is clicked
    const paymentMode = event.target.value;
    // Normalize the selectedOption value to lowercase
    const normalizedOption = selectedOption?.toLowerCase() || 'no';

    console.log("Sending data:", {
      email,
      name,
      city,
      contact,
      chanting,
      facilitator,
      occupation,
      initiatedName,
      wantsDhotiKurta: normalizedOption,
      paymentMode,
      dob,
      registeredBy,
    });
    if (paymentMode === 'Online Mode') {
      try {
        // Send user data to the backend
        // const response = await fetch(`${getBaseURL()}/api/registrationData`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     email,
        //     name,
        //     city,
        //     contact,
        //     chanting,
        //     facilitator,
        //     occupation,
        //     initiatedName,
        //     wantsDhotiKurta: selectedOption,
        //     paymentMode,
        //     dob,
        //     registeredBy,
        //   }),
        // });
    
        // if (!response.ok) {
        //   throw new Error('Failed to save registration ');
        // }
    
        // // Parse the response as JSON
        // const data = await response.json();
    

        // Send user data to the backend to save in TemporaryUser collection
        const response = await fetch(`${getBaseURL()}/api/save-temporary-user`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              email,
              name,
              city,
              contact,
              chanting,
              facilitator,
              occupation,
              initiatedName,
              wantsDhotiKurta: normalizedOption,
              paymentMode,
              dob,
              registeredBy,
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to save registration');
      }



        // Check if data contains the required fields
        // if (data && email && name && contact) {
        //   if (email && name && contact) {
        //   // Construct the URL for Razorpay payment page

          
          
        //   // Redirect to Razorpay Payment Page
        //   window.location.href = razorpayPaymentUrl;
        // } else {
        //   throw new Error('Missing expected data from backend response.');


        // Redirect to Razorpay Payment Page
      
      const razorpayPaymentUrl = normalizedOption === 'yes'
          ? 
          `https://pages.razorpay.com/pl_OkPDNjszhKDZlA/view?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}&phone=${encodeURIComponent(contact)}`
          :`https://pages.razorpay.com/pl_OkJxCAyz5DOgQN/view?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}&phone=${encodeURIComponent(contact)}`;

        //   // Navigate to the Onlinesuccess page with email and other necessary data
        // navigate('/onlinesuccess', {
        //   state: {
        //     email: email, // Pass email to Onlinesuccess page
        //     wantsDhotiKurta: normalizedOption
        //   }
        // });
      window.location.href = razorpayPaymentUrl;

    }catch (error) {
        console.error('Error during registration or redirection:', error);
        alert('An error occurred. Please try again.');
      }


    } else if(paymentMode === "Cash Mode") {
//       // Handle other payment modes if necessary
//       alert('Cash Mode selected. Please contact support to complete your registration.');
    
    
    try {
      const response = await fetch(`${getBaseURL()}/api/registrationData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          city,
          contact,
          chanting,
          facilitator,
          occupation,
          initiatedName,
          wantsDhotiKurta: normalizedOption,
          paymentMode,
          dob,
          registeredBy,
        }),
      });
    
  
      if (response.ok) {
        const responseData = await response.json();
        navigate('/success',{state: {promoCode:responseData.promoCode, wantsDhotiKurta: normalizedOption}});
      } else {
        const errorData = await response.json();
        console.error('Failed to save data:', response.statusText, errorData);
        alert(`Registration failed: ${response.statusText}`);
      }
    } catch (err) {
      console.error('Error saving data:', err);
      alert('An error occurred during registration. Please try again.');
    } finally{
      setLoading(false); //hide loader after process is complete
    }
    }
  };
  

  return (
    <main>
      <div className='centered-container'>
      <div className="content-container">
        <img src={img1} alt="Viplava Logo" className="viplava-paylogo" />
        <h2 className='head'>Choose a Payment Mode</h2>
        <p className='para'>Please select your preferred payment method to complete your registration.</p>
        {loading ? (
          <div className='loader'></div> // Display loader
        ) : (
        <div className="payment-options">
          <button onClick={handlePaymentOptionSelect} value="Cash Mode" className="payment-button">Cash Mode</button>
          <button onClick={handlePaymentOptionSelect} value="Online Mode" className="payment-button">Online Mode</button>
        </div>
        )}
      </div>
    </div>
    </main>
  );
};

export default PaymentMode;
