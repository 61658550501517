import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.scss';

gsap.registerPlugin(ScrollTrigger);

const ScrollAnimation = () => {
  const sectionRef = useRef(null);
  const cardRefs = useRef([]);
  const headingRef = useRef(null);

  const images2 = [
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793745/image_2_v6shyj.png',title:'HH Lokanath Swami Maharaj',desc:'ISKCON GBC & Head. Padyatra Ministry'},  // Replace with your image paths
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793744/image__5_-removebg-preview_stkm4i.png',title:'HH Bhakti Ashraya Vaisnava Swami',desc:'Senior ISKCON Sannyasi'},
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793743/327220700_705583754596599_5355692849064060972_n_y1dq07.jpg',title:'HH Guru Prasad Swami Maharaj',desc:'ISKCON GBC Chairman '},
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793744/image__4_-removebg-preview_hvpqqw.png',title:'HH Prabodhanand Saraswati Swami',desc:'Senior ISKCON Sannyasi'},
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793744/image__3_-removebg-preview_x0w4so.png',title:'HH Bhakti Anugraha Janardana Swami',desc:'Senior ISKCON Sannyasi'},
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793745/image_1_yzjv6t.png',title:'HG Sundar Gopal Prabhuji',desc:'Chairman, IIYC & Zonal Secretary, UP West Bihar'},
    {src:'https://res.cloudinary.com/dwneljbds/image/upload/v1723793745/image_qtkha1.png',title:'HG Adikarta Prabhuji',desc:'Temple President, ISKCON Ghaziabad'}
  ];


  useEffect(() => {
    const cards = cardRefs.current;
    const columns = 3; // Number of columns

    // Animate the heading
    gsap.fromTo(
      headingRef.current,
      { opacity: 0, y: -50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 85%',
          end: 'top 50%',
          scrub: true,
        },
      }
    );

    // Animate cards and text in the same column together
    for (let i = 0; i < columns; i++) {
      const columnItems = cards.filter((_, index) => index % columns === i);

      gsap.fromTo(
        columnItems,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 80%',
            end: 'top 20%',
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div className="scroll-container">
      <section ref={sectionRef} className="scroll-section">
        <h2 ref={headingRef} className="section-heading">Speaker Details</h2>
        <div className="cards-container">
          {images2.map((image, i) => (
            <div
              key={i}
              ref={(el) => (cardRefs.current[i] = el)}
              className="card-wrapper"
            >
              <div className="circular-card">
                <img src={image.src} alt={`Card ${i + 1}`} className="card-image" />
              </div>
              <p className="card-text"> {image.title}</p>
              <p className="card-text2"> {image.desc}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ScrollAnimation;
