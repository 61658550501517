import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from './contexts/ContextProvider';
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth0Provider } from "@auth0/auth0-react";

// const root = ReactDOM.createRoot();

//previous code
ReactDOM.render(
    <ContextProvider>
    {/* <Auth0Provider
      domain="dev-zs113ohqxnqcod3i.us.auth0.com"
      clientId="L4GlIRQFtz5ZMd8FAaMFxZiQA8zBoVop"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    > */}
      <BrowserRouter>
      <App />
      </BrowserRouter>
      
    {/* </Auth0Provider> */}
  </ContextProvider>,
  document.getElementById("root")
);

//code new
// ReactDOM.createRoot(document.getElementById('root')).render(
//   <ContextProvider>
//     <Auth0Provider
//       domain="dev-zs113ohqxnqcod3i.us.auth0.com"
//       clientId="L4GlIRQFtz5ZMd8FAaMFxZiQA8zBoVop"
//       authorizationParams={{
//         redirect_uri: window.location.origin,
//       }}
//     >
//       <BrowserRouter>
//       <App />
//       </BrowserRouter>
      
//     </Auth0Provider>
//   </ContextProvider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
