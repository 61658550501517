import React, { useEffect } from 'react';
// import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter,Router, Routes, Route, useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './index';
import { Dashboard, Registrations } from '../pages';


import { useStateContext } from '../contexts/ContextProvider';

const MainDashBoard = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu,
     currentColor, themeSettings, setThemeSettings } = useStateContext();
//   const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

//   if (isLoading) {
//     return <div>Loading...</div>; // Optional loading state
//   }

  return (
    
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      {/* <BrowserRouter> */}
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl
                 hover:bg-light-gray"
              >
                <FiSettings />
              </button>

            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar
             dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
                {/* <button
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="absolute right-4 top-4 p-2 text-white rounded"
                  style={{ backgroundColor: currentColor }}
                >
                  Logout
                </button> */}
              
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}
            
              <Routes>
              <Route path="/" element={<Dashboard />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/Registrations" element={<Registrations />} />
              </Routes>
              
            </div>
            <Footer />
          </div>
        </div>
      {/* </BrowserRouter> */}
    </div>
  );
};

export default MainDashBoard;
