const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    DARK: '#292929',
    LIGHT_WHITE: '#DADADA',
    GREY: '#666666',
    YELLOW: '#FFA800',
    RED: '#FF0000'
}

export default COLORS;