// import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
// import { BrowserRouter, Routes, Route,useNavigate } from 'react-router-dom';
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./Components1";
import { Dashboard, Registrations } from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";

import {
  BrowserRouter as Router,
  Navigate,
  useNavigate,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import "./App.scss";
import ContactContainer from "./containers/ContactContainer";
import DysContainer from "./containers/DysContainer";
import UmangContainer from "./containers/UmangContainer";
import AboutContainer from "./containers/AboutContainer";
import TncContainer from "./containers/TncContainer";
import PrivacyContainer from "./containers/PrivacyContainer";
import RefundContainer from "./containers/RefundContainer";
import UmangVolunteerContainer from "./containers/UmangVolunteerContainer";
import UmangRegListContainer from "./containers/UmangRegListContainer";
import { ROUTE } from "./constants";
import DysRegistrationForm from "./containers/DysRegistrationForm";
import DysRegListContainer from "./containers/DysRegListContainer";
import LoginScreen from "./containers/LoginContainer/Login";
import AppNavbar from "./components/AppNavbar";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { whatCanISee } from "./services/UmangService";
// import RegistrationForm from "./containers/RegistrationForm/RegistrationForm";
// import ViplavaRegistration from "./containers/RegistrationForm/ViplavaRegistration";
import ViplavaRegistrationForm from "./containers/RegistrationForm/ViplavaRegistrationForm";
import PaymentMode from "./containers/RegistrationForm/PaymentMode";
import Success from "./containers/RegistrationForm/Success";
// import AdminDashboard from "./containers/AdminDashboard";
// import newApp from "./newApp";
import MainDashBoard from "./Components1/MainDashBoard";
import Onlinesuccess from "./containers/RegistrationForm/onlinesuccess";
export const ProtectedRoute = ({ children }) => {
  const [, setICanSee] = useAtom(whatCanISeeAtom);

  const fetchWhatCanISee = () => {
    whatCanISee().then((res) => {
      const iCanSee = res.data.iCanSee;
      setICanSee(iCanSee);
    });
  };

  useEffect(() => {
    fetchWhatCanISee();
  }, []);

  const loc = window.location.href;
  const data = localStorage.getItem("user_data");
  if (!data) {
    return <Navigate to={`/login?redirect=${loc}`} />;
  }
  return (
    <div>
      <AppNavbar />
      {children}
    </div>
  );
};

export const userDataAtom = atom({});
export const setUserDataAtom = atom(null, (_, set, value) =>
  set(userDataAtom, value)
);
export const whatCanISeeAtom = atom([]);

function App() {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  // const { user, loginWithRedirect, isAuthenticated, logout, isLoading } =
  //   useAuth0();
  const navigate = useNavigate();
  // console.log("Current User", user);
  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  // useEffect(() => {
  //   // Redirect to the dashboard after login
  //   if (isAuthenticated) {
  //     navigate('/');
  //   }
  // }, [isAuthenticated, navigate]);

  //  const handleLogin = async () => {
  //    await loginWithRedirect();
  //  };
  // if (isLoading) {
  //   return <div>Loading...</div>; // Optional loading state
  // }

  //  if (!isAuthenticated) {
  //    return (
  //      <div className="flex justify-center items-center min-h-screen">
  //        <button
  //          onClick={() => loginWithRedirect()}
  //          className=" text-white px-4 py-2 rounded"
  //          style={{ backgroundColor: currentColor }}
  //        >
  //          Login
  //        </button>
  //      </div>
  //    );
  //  }

  // const [, setUserData] = useAtom(setUserDataAtom);
  // const [iCanSee] = useAtom(whatCanISeeAtom);

  // useEffect(() => {
  //   loadUserData();
  // }, []);

  // const loadUserData = () => {
  //   const data = JSON.parse(localStorage.getItem("user_data"));
  //   if (data) {
  //     setUserData(data);
  //   }
  // };

  return (
    <>
    {/* <BrowserRouter> */}
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <Routes>
          <Route path={ROUTE.HOME} element={<UmangContainer />} exact />
          <Route path={ROUTE.LOGIN} element={<LoginScreen />} exact />
          <Route path={ROUTE.CONTACT_US} element={<ContactContainer />} exact />
          <Route path={ROUTE.ABOUT} element={<AboutContainer />} exact />
          <Route path={ROUTE.TERMS} element={<TncContainer />} exact />
          <Route path={ROUTE.REFUND} element={<RefundContainer />} exact />
          <Route path={ROUTE.PRIVACY} element={<PrivacyContainer />} exact />
          <Route path={ROUTE.UTSAH} element={<UmangContainer />} exact />
          <Route path={ROUTE.DYS} element={<DysContainer />} exact />
          {/* <Route path={ROUTE.TERMS} element={<Terms />} exact />
          <Route path={ROUTE.PRIVACY} element={<PrivacyPolicy />} exact /> */}
          {/* <Route path={ROUTE.REGISTRATION_FORM} element={<RegistrationForm />} exact /> */}
          {/* <Route path={ROUTE.REGISTRATION_FORM} element={<ViplavaRegistration />} exact /> */}
          <Route
            path={ROUTE.REGISTRATION_FORM}
            element={<ViplavaRegistrationForm />}
            exact
          />
          <Route path={ROUTE.PAYMENT_MODE} element={<PaymentMode />} exact />
          <Route path={ROUTE.SUCCESS} element={<Success />} exact />
          <Route path={ROUTE.ONLINESUCCESS} element={<Onlinesuccess />} exact />

          
          {/* <Route
            path={ROUTE.EVENT_VOLUNTEER}
            element={
              <ProtectedRoute>
                {!!iCanSee.find((el) => el === "utsah") && (
                  <UmangVolunteerContainer />
                )}
              </ProtectedRoute>
            }
            exact
          />
          <Route
            path={ROUTE.EVENT_LIST}
            element={
              <ProtectedRoute>
                {!!iCanSee.find((el) => el === "utsah") && (
                  <UmangRegListContainer iCanSee={iCanSee}/>
                )}
              </ProtectedRoute>
            }
            exact
          />

          <Route
            path={ROUTE.DYS}
            element={
              <ProtectedRoute>
                {!!iCanSee.find((el) => el === "dys") && (
                  <DysRegistrationForm />
                )}
              </ProtectedRoute>
            }
            exact
          />
          <Route
            path={ROUTE.DYS_LIST_SESSIN}
            element={
              <ProtectedRoute>
                {!!iCanSee.find((el) => el === "dys") && (
                  <DysRegListContainer />
                )}
              </ProtectedRoute>
            }
            exact
          />
          <Route
            path={ROUTE.DYS_LIST}
            element={
              <ProtectedRoute>
                {!!iCanSee.find((el) => el === "dys") && (
                  <DysRegListContainer />
                )}
              </ProtectedRoute>
            }
            exact
          /> */}
        </Routes>
            <Routes>
          <Route path="/MainDashBoard" element={<MainDashBoard />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Registrations" element={<Registrations />} />
          </Routes>
            </div>
           
    </>
  );
}

export default App;
