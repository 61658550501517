import React, { useState, useEffect } from 'react';
import './style.scss';
import Thumbnail from '../Thumbnail';

const images1 = [
  { src: 'https://www.coastok.com/static/media/talk1.6653ad44942f40bab4ab.jpg', title: 'Enlightening Sessions', description: '' },
  { src: 'https://www.coastok.com/static/media/talk3.3564a9471d005edf839f.jpg', title: 'Dance Performances', description: '' },
  { src: 'https://www.coastok.com/static/media/talk2.862cfc0861591fa8d238.jpeg', title: 'Kirtan Mela', description: '' },
  { src: 'https://www.coastok.com/static/media/talk5.0c9506e2980fe7f720ed.jpg', title: 'Theatrical Drama', description: '' },
  { src: 'https://www.coastok.com/static/media/event32.51aa9a8944e87f27c9dd.jpg', title: 'Vedic Quiz', description: '' },
];
const images2 = [
  { src: 'https://www.coastok.com/static/media/event02.93ae8f729728e9e9c349.jpg', title: '', description: '' },
  { src: 'https://www.coastok.com/static/media/event32.51aa9a8944e87f27c9dd.jpg', title: '', description: '' },
  { src: 'https://www.coastok.com/static/media/event29.75cf62d4e14a2890ec81.jpg', title: '', description: '' },
  { src: 'https://www.coastok.com/static/media/event19.612f3369f221e3a25077.jpg', title: '', description: '' },
  { src: 'https://www.coastok.com/static/media/event05.dd698120b74a3e21afc0.jpg', title: '', description: '' },
];

const Slider = ({stack}) => {
    console.log(stack)
  const [itemActive, setItemActive] = useState(0);
  let images =[];
    if(stack===1){
        images = images1;
    }
    else{
        images = images2;
    }
  const countItem = images.length;

  const handleNext = () => {
    setItemActive((prev) => (prev + 1) % countItem);
  };

  const handlePrev = () => {
    setItemActive((prev) => (prev - 1 + countItem) % countItem);
  };

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(refreshInterval);
  }, [itemActive]);

  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <div className="slider">
      <div className="list">
        {images.map((image, index) => (
          <div key={index} className={`item ${index === itemActive ? 'active' : ''}`}>
            <img src={image.src} alt={image.title} />
            <div className="content">
              {stack===1?<p>Events</p>:<p>Glimpses</p>}
              <h2>{image.title}</h2>
              <p>{image.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="arrows">
        <button id="prev" onClick={handlePrev}>◀</button>
        <button id="next" onClick={handleNext}>▶</button>
      </div>

      <Thumbnail images={images} itemActive={itemActive} onThumbnailClick={handleThumbnailClick} />
    </div>
  );
};

export default Slider;
