import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import img from './img/thank you message.png';
import "./successpage.css"

const Onlinesuccess = () => {
    // const location = useLocation();
    // const [promoCode, setPromoCode] = useState('');
    // const [wantsDhotiKurta, setWantsDhotiKurta] = useState('no');
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const getBaseURL = () => {
        const host = window.location.host;
      
        if (host.includes('localhost')) {
          return 'http://localhost:3000'; // Local API URL
        } else {
          return 'https://www.iyfghaziabad.in'; // Production API URL
          }
        };




    // Normalize wantsDhotiKurta to lowercase for comparison
    // const normalizedDhotiKurta = wantsDhotiKurta.toLowerCase();

    // // Construct the referral URL
    // const referralUrl = `${getBaseURL()}/registration?promo=${promoCode}`;

    // Function to copy referral URL to clipboard
    // const copyToClipboard = () => {
    //     navigator.clipboard.writeText(referralUrl);
    //     alert("Referral link copied to clipboard!");
    // };
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error: {error}</div>;
    // }
    // Determine the amount to pay
    // const amountToPay = normalizedDhotiKurta === 'yes' ? 700 : 300;

    return (
        <div className='main'>
            <div style={{ padding: '20px', textAlign: 'center' }}>
            <div className='thankimage'>
                {/* Thank You Image */}
                <img
                    src={img}  // Replace with your actual image path
                    alt="Thank you"
                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
                />
                </div>

                <h1 className='head'>Thank you for registering for Viplava 2024</h1>
                {/* Additional Line */}
                <p className='amountpaid'>
                    Thank You for registering for Viplava. Your Referral URL is sent to your email. With the help of Referral URL you can refer you friends for this amazing youth festival.
                </p>
            </div>
        </div>
    );
};

export default Onlinesuccess;
