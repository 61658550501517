import React from 'react'
import "./style.scss";

const index = () => {
  return (
    <div className='header'>
        <div className='logo1'>
        <img src='https://res.cloudinary.com/dwneljbds/image/upload/v1723105883/cropped_image_tpi7dv.png'></img>
        <div className='text'>
            <h2>Connect</h2>
            <p>To Krishna</p>
        </div>
        </div>
        <div className='pane'>
        <a
          href="/registration"
          target="_self"
        >

        <button
                className="register-btn round-btn"
                style={{
                  backgroundcolor: "transparent",
                  color: "rgb(128, 0, 10)",
                  border: "2px solid rgb(255, 255, 255)",
                }}
                >
                REGISTER NOW
              </button>
          </a>
        </div>
    </div>
  )
}

export default index