import React from "react";
import { GoDotFill } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button } from "../Components1";
// earningData, medicalproBranding, recentTransactions, weeklyStats,
import { dropdownData, ecomPieChartData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import product9 from "../data/product9.jpg";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  PdfExport,Sort,Page
} from '@syncfusion/ej2-react-grids';
import * as XLSX from 'xlsx';
const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);
const getBaseURL = () => {
  const host = window.location.host;

  if (host.includes('localhost')) {
    return 'http://localhost:5000'; // Local API URL
  } else {
    return 'https://iyfghaziabad.in'; // Production API URL
    }
  };
const Dashboard = () => {
  const { currentColor } = useStateContext();
  const [registrationData, setRegistrationData] = useState([]);
  const [count, setCount] = useState(null);
  const [registrationFacilitatorData, setRegistrationFacilitatorData] = useState([]);
  useEffect(() => {
    const fetchTotalCount = async () => {
      try {
        const response = await axios.get(`${getBaseURL()}/api/totalRegistrationsCount`);
        setCount(response.data.count);
      } catch (error) {
        console.error('Error fetching total registrations count:', error);
      }
    };

    const fetchRegistrationData = async () => {
      try {
        const response = await axios.get(`${getBaseURL()}/api/getRegistrationCountByArea`);
        if (response.data.success) {
          const data = Object.entries(response.data.registrationCounts).map(([area, count]) => ({
            area,
            count
          }));
          setRegistrationData(data);
        }
      } catch (error) {
        console.error('Error fetching registration data by area:', error);
      }
    };
    const fetchRegistrationFacilitatorData = async () => {
      try {
        const response = await axios.get(`${getBaseURL()}/api/getRegistrationCountByFacilitator`);
        if (response.data.success) {
          const data = Object.entries(response.data.FacilitatorCounts).map(([Facilitators, count]) => ({
            Facilitators,
            count
          }));
          setRegistrationFacilitatorData(data);
        }
      } catch (error) {
        console.error('Error fetching registration data by area:', error);
      }
    };

    fetchTotalCount();
    fetchRegistrationData();
    fetchRegistrationFacilitatorData();
  }, []);
// Function to export data to CSV
const handleCsvExport = () => {
  const ws = XLSX.utils.json_to_sheet(registrationData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Registration Data');
  XLSX.writeFile(wb, 'registration_data.csv');
};
  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">Total Registrations</p>
              <p className="text-2xl font-bold" style={{ color: currentColor }}>
                {count}
              </p>
            </div>
          </div>
        </div>

        {/* Data Table for Registration Counts by Area */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl p-4 m-3 w-full lg:w-4/5">
          <h2 className="text-lg font-semibold mb-4" style={{ color: currentColor }}>
            Registration Counts by Area
          </h2>
          {/* Grid Component with Export Options */}
          {/* <button onClick={handleCsvExport()}
            className=' text-white p-2 rounded ml-5 mb-5'
            style={{ backgroundColor: currentColor }}
          >
            Download List
          </button> */}
          <GridComponent
            dataSource={registrationData}
            toolbar={[
              { text: 'Download List', prefixIcon: 'e-excel-export', id: 'csvExport' },
            ]} // Add custom CSV export button
            toolbarClick={(args) => {
              if (args.item.id === 'csvExport') {
                handleCsvExport(); // Call CSV export function
              }
            }}
            allowSorting={true}
            
          >
            <ColumnsDirective>
              <ColumnDirective field="area" headerText="Area" width="150" textAlign="Left" />
              <ColumnDirective field="count" headerText="Registration Count" width="150" textAlign="Right" />
            </ColumnsDirective>
            {/* Inject Toolbar and Export Services */}
            <Inject services={[Toolbar, PdfExport,Sort]} />
          </GridComponent>
        </div>
        {/* Data Table for Registration Counts by facilitator */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl p-4 m-3 w-full lg:w-4/5">
          <h2 className="text-lg font-semibold mb-4" style={{ color: currentColor }}>
            Registration Counts by Facilitator
          </h2>
          {/* Grid Component with Export Options */}
          {/* <button onClick={handleCsvExport()}
            className=' text-white p-2 rounded ml-5 mb-5'
            style={{ backgroundColor: currentColor }}
          >
            Download List
          </button> */}
          <GridComponent
            dataSource={registrationFacilitatorData}
            toolbar={[
              { text: 'Download List', prefixIcon: 'e-excel-export', id: 'csvExport' },
            ]} // Add custom CSV export button
            toolbarClick={(args) => {
              if (args.item.id === 'csvExport') {
                handleCsvExport(); // Call CSV export function
              }
            }}
            allowSorting={true}
            allowPaging
          >
            <ColumnsDirective>
              <ColumnDirective field="Facilitators" headerText="Facilitator" width="150" textAlign="Left" />
              <ColumnDirective field="count" headerText="Registration Count" width="150" textAlign="Right" />
            </ColumnsDirective>
            {/* Inject Toolbar and Export Services */}
            <Inject services={[Toolbar, PdfExport,Sort,Page]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
