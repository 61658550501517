import React, { useEffect } from "react";
import './style.scss'; // Make sure to create a corresponding CSS file for styles

const DysContainer = () => {
  // Razorpay button script
  useEffect(() => {
    const loadScript = () => {
      if (!document.getElementById('razorpay-embed-btn-js')) {
        const script = document.createElement('script');
        script.defer = true;
        script.id = 'razorpay-embed-btn-js';
        script.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js';
        document.body.appendChild(script);
      } else {
        const rzp = window['__rzp__'];
        rzp && rzp.init && rzp.init();
      }
    };
    loadScript();
  }, []);

  return (
    <div className="Rishikesh">
      <div id="front" className="front">
        <h1>Discover Yourself Course</h1>
        <h2>Let's explore yourself in a new way which you may not know</h2>
        <video
          src="Discover Your Self - 6 session course (1).mp4"
          controls
          autoPlay
        ></video>
        <div
          className="razorpay-embed-btn"
          data-url="https://pages.razorpay.com/pl_OKZjCQATJhetP4/view"
          data-text="Register Now"
          data-color="#FFD700"
          data-size="medium"
        ></div>
      </div>

      <div id="Dys">
        {/* Card components for each session */}
        <Card
          id="card1"
          title="Session-1:Can A Scientist Believe in God ?"
          description="After so much Advancement,Does Scientist still believe in god ?"
        />
        <Card
          id="card2"
          title="Session-2:Getting Eyes To See God"
          description="No one has seen god yet? Is it possible to see god ?"
        />
      </div>

      <div id="Dys">
        <Card
          id="card3"
          title="Session-3:Vedas:The Privilege Of Humanity"
          description="Does Vedas Have Perfect Knowledge or Is It a Fictitious Story?"
        />
        <Card
          id="card4"
          title="Session-4:Science Of Soul"
          description="Who am I? Body or Soul? Does the Soul Exist?"
        />
      </div>

      <div id="Dys">
        <Card
          id="card5"
          title="Session-5:Substance And Shadow"
          description="Why Things in this world are not permanent? Can we Have Permanent Things?"
        />
        <Card
          id="card6"
          title="Session-6:If God is One,Why So Many Religion ?"
          description="All Says That God is one so why Religion like Hinduism, Islam, Christianity and etc Exists"
        />
      </div>

      <div id="Dys" className="poster">
        <img src="dysposter.jpeg" alt="Poster" />
      </div>

      <div id="Dys" className="regform">
        <h3>Click at below button for registration get registered Yourself in Dys</h3>
        <div
          className="razorpay-embed-btn"
          data-url="https://pages.razorpay.com/pl_OKZjCQATJhetP4/view"
          data-text="Register Now"
          data-color="#FFD700"
          data-size="medium"
        ></div>
      </div>

      <div id="Dys">
        <p id="hare">
          harer nāma harer nāma harer nāmaiva kevalam kalau nāsty eva nāsty eva nāsty eva gatir anyathā
        </p>
      </div>

      <div className="footer">
        For any queries @Sunny Teotia at 9058734868 and @Hg Raghav Kripa pr at 9953522058 or mail at sunnyteotia2005@gmail.com
      </div>
    </div>
  );
};

const Card = ({ id, title, description }) => {
  return (
    <div className={`card ${id}`}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default DysContainer;
